import React, { useContext  } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import CartContext from "../context/cartContext";

import redirectToCheckout from './redirectToCheckout';
import { useSelector, useDispatch } from 'react-redux';


const ProductList = ({}) => {

  const textStyle = {
    color: 'black', // Set the text color to black
  };
  const cart = useSelector(state => state.cart);
  const dispatch = useDispatch();

  const addToCart = item => {
    // console.log(item)
    dispatch({ type: 'ADD_TO_CART', item });
  };

    const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "product-post" } } }
        sort: { fields: [frontmatter___tags], order: ASC }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              unit_amount
              description
              name
              featuredimage {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  `);



  return (
    <div>
      {data.allMarkdownRemark.edges.map(({ node: product }) => (
        <div key={product.id}>
          <div className="container"  style={{width:'80%'}}>
          <div className="columns is-vcentered" style={{paddingTop: 75, paddingBottom:75}}>
              <div className="column  " >
                <div style={{padding: 10}} >
                <Link to={product.fields.slug}>

                  <h2 className="has-text-weight-semibold is-size-5">
                    {product.frontmatter.name}
                  </h2>
                  </Link>

                </div>

                <div style={{padding: 10}}  >
                <Link  to={product.fields.slug}>

                  <p style={textStyle}>{product.frontmatter.description}</p>
                  </Link>

                  <div style={{marginTop : 10}}>
                    <h2>£{product.frontmatter.unit_amount /100}</h2>
                  </div>
                  <Link  to={product.fields.slug}>

                    <p>See more</p>
                  </Link>
                  {/* <div>
                    <div style={{paddingTop: 10}}>
                    <a onClick={() => addToCart({name : product.frontmatter.name, unit_amount:  product.frontmatter.unit_amount, id :product.id, image: product.frontmatter.featuredimage })}>
                      <p >Add to Cart</p>
                    </a>
                    </div>
                    <div style={{paddingTop: 20}}>
                      <a href="#" onClick={(e) => redirectToCheckout.redirectToCheckout(e, {name : product.frontmatter.name, unit_amount:  product.frontmatter.unit_amount})}>
                        <p >Buy Now</p>
                      </a>
                    </div>
                  </div> */}
                </div>

              </div>
            <div className="tile is-parent" >
            <Link to={product.fields.slug}>

              <article className="tile is-child">
                <PreviewCompatibleImage imageInfo={{image: product.frontmatter.featuredimage}} />
              </article>
              </Link>

              </div>
            </div>
        </div>
        </div>
      ))}

     

    </div>
  )
}

export default ProductList
